import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import ptsData from './data/pts_lookup.json'
import {
  Card,
  CardActions,
  CardActionArea,
  Typography,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Icon
} from '@material-ui/core'
import { get as gv, isArray } from 'lodash'

const FROM_ROMAN = {
  'i': 'i1',
  'ii': 'i2',
  'iii': 'i3',
  'iv': 'i4',
  'v': 'i5',
  'vi': 'i6',
  'vii': 'i7',
  'viii': 'i8',
  'ix': 'i9',
  'x': 'i10'
}

class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedBook: '',
      selectedDiv: '',
      selectedNum: ''
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  renderResults() {
    const result = gv(ptsData, `${this.state.selectedBook}.${this.state.selectedDiv}.${this.state.selectedNum}`, null) ||
      gv(ptsData, `${this.state.selectedBook}.${this.state.selectedNum}`, null)

    if (result === null) {
      return null
    }

    const [suttaId, ptsRef] = result

    const bookId = suttaId.match(/[^0-9]+/gi)

    return <div className='results'>
      <b>{ suttaId.toUpperCase() } </b>
      (
      <a href={`https://suttacentral.net/${suttaId}/pli/ms#${ptsRef}`} target='_blank'>
        Pali
        <Icon>open_in_new</Icon>
      </a>
      { bookId && ['mn', 'sn', 'an', 'dn'].includes(bookId[0])
        ? <a
          href={`https://suttacentral.net/${suttaId}/en/sujato#${ptsRef}`}
          target='_blank'>
          English (Sujato)
          <Icon>open_in_new</Icon>
        </a>
        : null
      }
      { bookId && ['mn', 'sn', 'an'].includes(bookId[0])
        ? <a
          href={`https://suttacentral.net/${suttaId}/en/bodhi#${this.state.selectedBook}.${this.state.selectedDiv}.${this.state.selectedNum}`}
          target='_blank'>
          English (Bodhi)
          <Icon>open_in_new</Icon>
        </a>
        : null
      }
      )
    </div>
  }

  render() {
    return (
      <div>
        <a href='https://dhamma-dana.de'>
          <img src='/logo.png' />
        </a>
        <Card className='main'>
        <hr />
        <h2>PTS Converter</h2>
        <p>Bitte die gesuchte PTS Stelle auswählen / Please select the PTS position you want to look up:</p>
        <FormControl>
          <InputLabel>Book</InputLabel>
          <Select
            value={this.state.selectedBook}
            style={{ minWidth: '80px' }}
            onChange={(e) => this.setState({
              selectedBook: e.target.value,
              selectedDiv: '',
              selectedNum: ''
            })}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            { Object
              .keys(ptsData)
              .sort()
              .map((k) => <MenuItem value={k} key={k}>{k}</MenuItem>)}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Division</InputLabel>
          <Select
            value={this.state.selectedDiv}
            style={{ minWidth: '80px' }}
            onChange={(e) => this.setState({
              selectedDiv: e.target.value,
              selectedNum: ''
            })}
            disabled={!this.state.selectedBook}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            { !isArray(ptsData[this.state.selectedBook]) && typeof ptsData[this.state.selectedBook] !== 'undefined'
              ? Object
                .keys(ptsData[this.state.selectedBook])
                .map(k => <MenuItem value={k} key={k}>{k}</MenuItem>)
              : null
            }
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel>Number</InputLabel>
          <Select
            name='selectedNum'
            style={{ minWidth: '80px' }}
            value={this.state.selectedNum}
            onChange={(e) => this.setState({ selectedNum: e.target.value })}
            disabled={!isArray(ptsData[this.state.selectedBook]) && !isArray(gv(ptsData, `${this.state.selectedBook}.${this.state.selectedDiv}`, null))}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            { isArray(gv(ptsData, `${this.state.selectedBook}.${this.state.selectedDiv}`, null))
              ? Object
                .entries(gv(ptsData, `${this.state.selectedBook}.${this.state.selectedDiv}`, null))
                .filter(([k,v]) => v !== null)
                .map(([k,v]) => <MenuItem value={k} key={k}>{k}</MenuItem>)
              : isArray(ptsData[this.state.selectedBook])
                ? Object
                  .entries(gv(ptsData, `${this.state.selectedBook}`, null))
                  .filter(([k,v]) => v !== null)
                  .map(([k,v]) => <MenuItem value={k} key={k}>{k}</MenuItem>)
                : null
            }
          </Select>
        </FormControl>

        { this.renderResults() }

        <hr />

        </Card>
        <Card className='info'>
          <b>Hinweise:</b>
          <ul>
            <li>Dieses Tool hat den Zweck, das Nachschlagen von PTS-Refernzen (wie z.B. "MN I 127") zu erleichtern. Nach Auswählen der Beistandteile "MN", "i", "127" wird die entsprechende Textkennung "MN21" mit Link zum Nachschlagen auf <a href='https://suttacentral.net/' target='_BLANK'>suttacentral.net</a> angezeigt.</li>
            <li>Da die Referenzen automatisch konvertiert wurden, besteht keine Garantie, dass die herausgegebene Stelle auch die Richtige ist. Es kann vor allem sein, dass sich die gesuchte Stelle tatsächlich einen Text weiter oder zurück befindet. Bitte überprüfen Sie deshalb manuell, ob das Ergebnis auch stimmt!</li>
            <li>Es wurde versucht, direkte Links zu den Texten auf <a href='https://suttacentral.net/' target='_BLANK'>suttacentral.net</a> anzuzeigen. Im Idealfall springt die Seite beim Aufrufen an den Beginn der Paragraphen der PTS Position. Allerdings ist dies derzeit nur bei manchen so.</li>
            <li>Die verwendeten Daten stammen ebenfalls von <a href='https://suttacentral.net/' target='_BLANK'>suttacentral.net</a> (<a href='https://github.com/suttacentral' target='_BLANK'>https://github.com/suttacentral</a>). Dank der öffentlich zugänglichen Daten von dort war die Erstellung dieses Tools möglich!</li>
            <li>Bitte senden Sie Fehlermeldungen oder Verbesserungsvorschläge an <a href='mailto:bgm.m@web.de'>bgm.m@web.de</a> oder öffnen ein Ticket auf <a href='https://gitlab.com/olastor/pts-converter/issues' target='_BLANK'>https://gitlab.com/olastor/pts-converter/issues</a></li>
          </ul>

          <b>Notes:</b>
          <ul>
            <li>The purpose of this tool is to make the lookup of PTS references (i.e. "MN I 127") easier. After selection of the parts "MN", "i", "127" it returns the text reference "MN21" along with the corresponding link of the text to <a href='https://suttacentral.net/' target='_BLANK'>suttacentral.net</a>.</li>
            <li>Because the conversion has been made automatically it is not guaranteed that the returned position also is the correct one. Especially it may be that the correct position actually is one text before or after the returned one. Please therefore manually verify the text you have looked up!</li>
            <li>It has been tried to add links to <a href='https://suttacentral.net/' target='_BLANK'>suttacentral.net</a> along with the text reference. Ideally the page also jumps after opening to the beginning paragraph of the PTS position. But this currently only works for some links.</li>
            <li>For conversion the data from <a href='https://suttacentral.net/' target='_BLANK'>suttacentral.net</a> (<a href='https://github.com/suttacentral' target='_BLANK'>https://github.com/suttacentral</a>) was used. Thanks to their public repositories the creation of this tool was possible.</li>
            <li>Please send issue reports or ideas to <a href='mailto:bgm.m@web.de'>bgm.m@web.de</a> or open a ticket at <a href='https://gitlab.com/olastor/pts-converter/issues' target='_BLANK'>https://gitlab.com/olastor/pts-converter/issues</a></li>
          </ul>
        </Card>


        <footer>
          <a href='https://www.dhamma-dana.de/impressum' target='_BLANK'>Impressum</a>&nbsp;&nbsp;
          <a href='https://www.dhamma-dana.de/datenschutz' target='_BLANK'>Datenschutz</a>
        </footer>
      </div>
    );
  }
}

export default App;
